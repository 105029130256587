import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Question from '../components/Question';
import LettBlanding from "../data/quiz/lett-blanding-quiz.json";
import Film from "../data/quiz/film.json";
import Musikk from "../data/quiz/musikk.json";

const KahootSporsmal = props => {
  const { data } = props.data;

  const title = "Kahoot spørsmål";

  return (
      <Layout showRelated={true}>
        <SEO
            description="Trenger du spørsmål til en Kahoot-quiz? her finner du mange spørsmål og svar."
            title={title} />
        <div className="quiz">
          {<div className="justify-content-start pb-2">
            <div className="pt-6">
              <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
          </div>}
          <div>
            {LettBlanding && LettBlanding.map((it, index) => {
              return (
                <Question addBtn={true} index={index+1} data={it}/>
              )})}
              <h2>Musikk</h2>
              {Musikk && Musikk.map((it, index) => {
              return (
                <Question addBtn={true} index={index+1} data={it}/>
              )})}
              <h2>Film</h2>
              {Film && Film.map((it, index) => {
              return (
                <Question addBtn={true} index={index+1} data={it}/>
              )})}
          </div>
        </div>
      </Layout>
  );
};

export const query = graphql`
  query {
    data: markdownRemark(fileAbsolutePath: {regex: "/(Kahoot.md)/"}) {
      html
    }
  }
`;

export default KahootSporsmal;
